import { createElement, isValidElement } from "react"

export function createElementNodeOrHTML (Component, content, props = null) {
  if (isValidElement(content)) {
    return createElement(Component, props, content)
  }

  return createElement(Component, {
    ...props,
    dangerouslySetInnerHTML: { __html: content },
  })
}
