import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import darken from "polished/lib/color/darken"
import { createElementNodeOrHTML } from "../../utils/react"

const FancyTitle = styled.div`
  font-weight: 900;
  background-color: ${props => props.bgColor || props.theme.colors.primary};
  font-size: ${props => props.theme.fonts.sizes.x_large};
  color: white;
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0 25px;
  position: relative;
  max-width: 90%;
  height: 100px;

  ${breakpoint(`medium`)`
    margin-left: 15px;
    margin-right: 15px;
  `}

  ${breakpoint(`small`)`
    height: 75px;
    max-width: 100%;
    
    &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
  }

  &::before {
    right: 100%;
    border-width: 0 0 15px 7px;
    border-color: transparent transparent
      ${props => darken(0.2, props.bgColor || props.theme.colors.primary)}
      transparent;
  }

  &::after {
    left: 100%;
    border-width: 15px 0 0 7px;
    border-color: transparent transparent transparent
      ${props => darken(0.2, props.bgColor || props.theme.colors.primary)};
  }
  
  `}
`

const FancyBottom = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 50px;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 500px;
    background-color: ${props => props.bgColor || props.theme.colors.primary};
  }

  &::before {
    transform-origin: right bottom;
    right: 50%;
    transform: translateY(-500px) rotate(-2deg);
  }
  &::after {
    transform-origin: left bottom;
    left: 50%;
    transform: translateY(-500px) rotate(2deg);
  }
`

const FancyTitleContainer = styled.div`
  transform: translateY(-15px);
  margin-bottom: 32px;
  text-align: center;
`

export default function BlockFancyTitle ({ children, seo, bgColor }) {
  return (
    <FancyTitleContainer data-testid={arguments[0][`data-testid`] || undefined}>
      <FancyTitle as={seo} bgColor={bgColor}>
        {createElementNodeOrHTML(`div`, children)}
        <FancyBottom bgColor={bgColor} />
      </FancyTitle>
    </FancyTitleContainer>
  )
}

BlockFancyTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  seo: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
}
